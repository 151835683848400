<template>
  <div
    ref="component"
    class="InlineField__Component"
    @mouseover="hover(true)"
    @mouseleave="hover(false)"
    @keydown.tab="onTab"
    :class="{
      'has-value': value,
      invalid: invalid || (required && !value),
      'read-only': readOnly,
      'hide-hover': hideHover,
    }"
  >
    <label>
      <div class="InlineField__Component__Placeholder">
        <span class="InlineField__Component__Placeholder__Text">{{
          label
        }}</span>
        <!-- <span class="InlineField__Component__Placeholder__Icon">
                <b-icon
                    v-if="!hideHover"
                    icon="pencil"
                    size="is-small">
                </b-icon>
                </span> -->
      </div>
      <b-datepicker
        locale="en-GB"
        placeholder="בחר תאריך"
        :day-names="['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש']"
        :month-names="[
          'ינואר',
          'פברואר',
          'מרץ',
          'אפריל',
          'מאי',
          'יוני',
          'יולי',
          'אוגוסט',
          'ספטמבר',
          'אוקטובר',
          'נובמבר',
          'דצמבר',
        ]"
        icon="calendar-today"
        @input="setDate"
        :value="inputVal"
        editable
        append-to-body
        :disabled="readOnly"
        :type="type && type"
        trap-focus
        v-mask="'##/##/####'"
        :tabindex=tabIndex
      >
      </b-datepicker>
    </label>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "FieldInlineDate",
  props: ["value", "label", "required", "invalid", "readOnly", "hideHover", "vMask", "tabIndex", "type"],
  computed: {
    inputVal: {
      get() {
        if (this.value) {
          return new Date(this.value);
        }
        return null;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      isFocused: false,
    };
  },
  methods: {
    setDate(value) {
      // let date = moment(value, ["YYYY-MM-DDThh:mm:ss", "DD/MM/YYYY"], true);
      const userTimezoneOffset = value.getTimezoneOffset() * 60000;
      let date = new Date(value.getTime() - userTimezoneOffset);
      date = moment(date, ["YYYY-MM-DDThh:mm:ss", "DD/MM/YYYY"], true);
      // const date = moment(value, ["YYYY-MM-DDThh:mm:ss", "DD/MM/YYYY"], true);
      if (date.isValid()) {
        this.$emit("input", date.toDate());
        this.$emit("onChange", date);
      } else {
        this.$emit("onChange", this.inputVal);
      }
    },
    hover(isHover) {
      if (isHover) {
        this.$refs.component.classList.add("is-hover");
      } else {
        this.$refs.component.classList.remove("is-hover");
      }
    },
    focus(isFocused) {
      if (isFocused) {
        this.$refs.component.classList.add("is-focused");
      } else {
        this.$refs.component.classList.remove("is-focused");
      }
    },
    onTab() {
      if (this.$children[0] && this.$children[0].togglePicker) {
        this.$children[0].togglePicker(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.InlineField__Component {
  padding: 5px 0;

  &:not(.read-only) {
    &.invalid .InlineField__Component__Placeholder,
    &.is-hover .InlineField__Component__Placeholder,
    &.is-focused .InlineField__Component__Placeholder {
      transform: translateY(0px);
    }
    &.is-hover:not(.hide-hover) ::v-deep input,
    &.is-focused ::v-deep input {
      border-bottom: 1px solid #00a4bd !important;
    }
  }
  &.has-value .InlineField__Component__Placeholder {
    transform: translateY(0px);
  }

  &.is-hover ::v-deep .vs__actions {
    opacity: 1;
  }

  .InlineField__Component__Placeholder__Icon {
    color: #00a4bd;
    opacity: 0;
    cursor: pointer;
    top: 20px;
    position: relative;
  }
  &.read-only {
    .InlineField__Component__Placeholder__Icon {
      display: none;
    }
  }

  &.is-hover:not(.is-focused):not(.has-value)
    .InlineField__Component__Placeholder__Icon {
    opacity: 1;
  }

  .InlineField__Component__Placeholder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: translateY(10px);
    transition: transform linear 0.1s;
    position: relative;
    z-index: 1;

    .InlineField__Component__Placeholder__Text {
      color: #287994;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &.invalid label ::v-deep input {
    background-color: #e9f5ff;
  }
  // &.invalid label::after {
  //     content: '';
  //     width: 50%;
  //     height: 100%;
  //     background: linear-gradient(
  //     -90deg
  //     , #ffc0bb5e 65%, #ffffff00 100%);
  //     position: absolute;
  //     border-right: 3px solid #ff6d6d;
  //     right: -10px;
  //     z-index: 0;
  // }
  label {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
  }
  &.read-only {
    label {
      cursor: default;
    }
  }
  ::v-deep input,
  ::v-deep .v-select {
    border: none;
    border-bottom: 1px solid transparent;
    width: 100%;
    border-radius: 0;
    transition: all linear 0.1s;
    font-size: 16px !important;
    box-shadow: none;

    &:focus {
      outline: none;
      cursor: text;
    }
  }
  ::v-deep .datepicker input {
    border: none;

    &[disabled].input {
      background-color: transparent;
      color: black;
      padding: 0;
    }
  }
  ::v-deep .datepicker .dropdown-menu {
    direction: ltr;
    min-width: 20rem;
  }
}
</style>
