var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "component",
      staticClass: "InlineField__Component",
      class: {
        "has-value": _vm.value,
        invalid: _vm.invalid || (_vm.required && !_vm.value),
        "read-only": _vm.readOnly,
        "hide-hover": _vm.hideHover
      },
      on: {
        mouseover: function($event) {
          return _vm.hover(true)
        },
        mouseleave: function($event) {
          return _vm.hover(false)
        },
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
          ) {
            return null
          }
          return _vm.onTab($event)
        }
      }
    },
    [
      _c(
        "label",
        [
          _c("div", { staticClass: "InlineField__Component__Placeholder" }, [
            _c(
              "span",
              { staticClass: "InlineField__Component__Placeholder__Text" },
              [_vm._v(_vm._s(_vm.label))]
            )
          ]),
          _c("b-datepicker", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: "##/##/####",
                expression: "'##/##/####'"
              }
            ],
            attrs: {
              locale: "en-GB",
              placeholder: "בחר תאריך",
              "day-names": ["א", "ב", "ג", "ד", "ה", "ו", "ש"],
              "month-names": [
                "ינואר",
                "פברואר",
                "מרץ",
                "אפריל",
                "מאי",
                "יוני",
                "יולי",
                "אוגוסט",
                "ספטמבר",
                "אוקטובר",
                "נובמבר",
                "דצמבר"
              ],
              icon: "calendar-today",
              value: _vm.inputVal,
              editable: "",
              "append-to-body": "",
              disabled: _vm.readOnly,
              type: _vm.type && _vm.type,
              "trap-focus": "",
              tabindex: _vm.tabIndex
            },
            on: { input: _vm.setDate }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }